import React, { useEffect } from 'react';
import {
	Button,
	Divider,
	Flex,
	FormControl,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Spinner,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { BonfireAPI, ApiType } from '../../../models/BonfireApi';
import { useParams } from 'react-router-dom';
import 'react-resizable/css/styles.css';
import config from '../../../utils/config';
import Locale from '../../../utils/translations';

const RenderSessionSimulations: React.FC = () => {
	const { onOpen, onClose, isOpen } = useDisclosure();
	const { sessionId } = useParams<{ sessionId: string }>();
	const [originalSession, setOriginalSession] = React.useState<any>();
	const [newSimulation, setNewSimulation] = React.useState<any>();
	const [simulations, setSimulations] = React.useState<any>([]);
	const [haveRecording, setHaveRecording] = React.useState<boolean>(false);
	const [isCreating, setIsCreating] = React.useState<boolean>(false);
	const buffer = React.useRef<any>();
	const reloadInterval = React.useRef<any>();

	useEffect(() => {
		startReloadDataTimer();
		return () => {
			clearInterval(reloadInterval.current);
		};
	}, []);

	const onTagChange = (key: string, value: string) => {
		const session = { ...newSimulation };
		session.tags = session.tags.map((tag: any) => {
			if (tag.key === key) {
				tag.value = value;
			}
			return tag;
		});
		setNewSimulation(session);
	};

	const loadData = async () => {
		try {
			const api = new BonfireAPI(ApiType.QaApi);
			const simulationsRemoteData = await api.post('simulations/list', {
				sessionId,
			});
			simulationsRemoteData.map((simulation: any) => {
				if (simulation.parentSessionId === simulation.sessionId) {
					setOriginalSession(simulation);
				}
			});
			setSimulations(simulationsRemoteData);
		} catch (error) {
			console.error(error);
		}
	};

	const openNewSimulationModal = () => {
		const simulation = { ...originalSession };
		simulation.name = '';
		simulation.sessionId = '-';
		const index = simulation.tags.findIndex((item: any) => item.key === 'sessionId');
		if (index !== -1) {
			simulation.tags.splice(index, 1);
		}
		simulation.tags.push({
			key: 'replayUrl',
			value: `${originalSession.aiCoreUrl}/captions/transcript?sessionId=${originalSession.sessionId}`,
		});

		setNewSimulation(simulation);
		onOpen();
	};

	const startReloadDataTimer = () => {
		reloadInterval.current = setInterval(() => {
			loadData();
		}, 3000);
	};

	const fetchAudioFile = async () => {
		if (!sessionId) {
			return;
		}
		if (buffer.current) {
			return;
		}

		let response;

		try {
			const url = `${config.qa_api.url}/files/getFile?type=recordings&id=${sessionId}`;
			response = await fetch(url, {
				method: 'GET',
				credentials: 'include',
			});
			if (response.status !== 200) {
				return;
			}
			setHaveRecording(true);
		} catch (error) {
			return;
		}
	};

	const addNewSimulation = async () => {
		newSimulation.parentSessionId = originalSession.sessionId;
		const url = `${config.qa_api.url}/simulations/add`;
		const api = new BonfireAPI(ApiType.QaApi);
		setIsCreating(true);
		try {
			const response = await api.post(url, newSimulation);
			if (response.response.data.error) {
				setIsCreating(false);
				return;
			}
		} catch (error) {
			console.error(error);
		}
		await loadData();
		setIsCreating(false);
		onClose();
	};

	const startSimulation = async (simulationSessionId: string) => {
		const url = `${config.qa_api.url}/simulations/start`;
		const api = new BonfireAPI(ApiType.QaApi);
		await api.post(url, { sessionId: simulationSessionId });
		await loadData();
	};

	const startCalculation = async (simulationSessionId: string) => {
		const url = `${config.qa_api.url}/simulations/calculate`;
		const api = new BonfireAPI(ApiType.QaApi);
		await api.post(url, { sessionId: simulationSessionId });
		await loadData();
	};

	useEffect(() => {
		loadData();
		fetchAudioFile();
	}, []);

	const findStat = (simulatedSession: any, type: string, language: string, key: string) => {
		const stats = simulatedSession.stats.find((stat: any) => stat.key === language);
		if (!stats) {
			return 0;
		}
		if (type === 'fragmentStats') {
			return stats.value.fragmentStats[key];
		} else if (type === 'translationsQuality') {
			return Math.floor(stats.value.quality[key] * 100) / 100 || 0;
		} else if (type === 'translationsLatency') {
			return stats.value.translationsLatency || 0;
		}
		return 0;
	};

	const renderStat = (simulatedSession: any, type: string, isFirst: boolean = false) => {
		let title = '';

		if (type === 'fragmentStats') {
			title = 'Fragment length';
		} else if (type === 'translationsQuality') {
			title = 'Translations quality';
		} else if (type === 'translationsLatency') {
			title = 'Translations latency';
		}
		const languages = simulatedSession.stats.map((stat: any) => stat.key);

		if (languages.length === 0) {
			return null;
		}

		return (
			<Flex flexDirection={'column'} flex={1}>
				<Flex
					alignItems={'center'}
					justifyContent={'center'}
					backgroundColor="#adedf4"
					p={5}
					borderWidth={1}
					borderColor={'#CCC'}
				>
					<Text mr={3} fontSize={16} fontWeight={'bold'}>
						{title}
					</Text>
				</Flex>
				<Flex flexDirection="column">
					<Flex flexDirection="row" backgroundColor="#0b9cbb">
						{isFirst && <Flex width={100} />}
						{languages.map((language: string) => (
							<Flex
								alignItems="center"
								justifyContent={'center'}
								flex={1}
								borderWidth={1}
								borderColor={'#CCC'}
								key={language}
							>
								{language.toUpperCase()}
							</Flex>
						))}
					</Flex>
					<Flex alignItems={'center'} justifyContent={'center'} backgroundColor="#eee">
						<Flex flexDirection="column" borderWidth={1} borderColor={'#CCC'} flex={1}>
							<Flex flex={1}>
								{isFirst && (
									<Flex
										width={100}
										justifyContent={'center'}
										alignItems={'center'}
										backgroundColor="#0b9cbb"
										borderWidth={1}
										borderColor={'#CCC'}
									>
										Min
									</Flex>
								)}
								<Flex flex={1}>
									{languages.map((language: any) => (
										<Flex
											flex={1}
											justifyContent={'center'}
											alignItems={'center'}
											borderWidth={1}
											borderColor={'#CCC'}
											key={'min_' + language}
										>
											<Text fontSize={16}>
												{findStat(simulatedSession, type, language, 'min')}
											</Text>
										</Flex>
									))}
								</Flex>
							</Flex>
							<Flex flex={1}>
								{isFirst && (
									<Flex
										width={100}
										justifyContent={'center'}
										alignItems={'center'}
										backgroundColor="#0b9cbb"
										borderWidth={1}
										borderColor={'#CCC'}
									>
										Max
									</Flex>
								)}
								<Flex flex={1}>
									{languages.map((language: any) => (
										<Flex
											flex={1}
											justifyContent={'center'}
											alignItems={'center'}
											borderWidth={1}
											borderColor={'#CCC'}
											key={'max_' + language}
										>
											<Text fontSize={16}>
												{findStat(simulatedSession, type, language, 'max')}
											</Text>
										</Flex>
									))}
								</Flex>
							</Flex>
							<Flex flex={1}>
								{isFirst && (
									<Flex
										width={100}
										justifyContent={'center'}
										alignItems={'center'}
										backgroundColor="#0b9cbb"
										borderWidth={1}
										borderColor={'#CCC'}
									>
										Avg
									</Flex>
								)}
								<Flex flex={1}>
									{languages.map((language: any) => (
										<Flex
											flex={1}
											justifyContent={'center'}
											alignItems={'center'}
											borderWidth={1}
											borderColor={'#CCC'}
											key={'avg_' + language}
										>
											<Text fontSize={16}>
												{findStat(simulatedSession, type, language, 'avg')}
											</Text>
										</Flex>
									))}
								</Flex>
							</Flex>
						</Flex>
					</Flex>
				</Flex>
			</Flex>
		);
	};

	return (
		<Flex flexDirection={'column'}>
			{simulations.length > 0 &&
				simulations.map((simulation: any) => {
					const isParent = simulation.sessionId === simulation.parentSessionId;
					return (
						<Flex
							flexDirection={'column'}
							borderWidth={1}
							p={5}
							key={simulation.sessionId}
							mb={5}
							backgroundColor={isParent ? '#83c4bf55' : '#fff'}
						>
							{isParent && (
								<Button onClick={openNewSimulationModal} alignSelf={'flex-end'}>
									+ Add new simulation
								</Button>
							)}

							{simulation.status === 'not_started' && (
								<Button
									onClick={() => {
										startSimulation(simulation.sessionId);
									}}
									alignSelf={'flex-end'}
								>
									Start
								</Button>
							)}
							{simulation.status === 'completed' && (
								<Button
									mt={5}
									onClick={() => {
										startCalculation(simulation.sessionId);
									}}
									alignSelf={'flex-end'}
								>
									Calculate
								</Button>
							)}

							<Flex alignItems={'center'} justifyContent={'flex-start'} mb={5}>
								<Text mr={3} fontWeight={'bold'}>
									Session name:{' '}
								</Text>
								<Text mr={3}>{simulation.name}</Text>
							</Flex>
							<Flex alignItems={'center'} justifyContent={'flex-start'} mb={5}>
								<Text mr={3} fontWeight={'bold'}>
									Session env:{' '}
								</Text>
								<Text mr={3}>{simulation.aiCoreUrl}</Text>
							</Flex>
							<Flex alignItems={'center'} justifyContent={'flex-start'} mb={5}>
								<Text mr={3} fontWeight={'bold'}>
									Session Id:{' '}
								</Text>
								<Text mr={3}>{simulation.sessionId}</Text>
							</Flex>
							<Flex alignItems={'center'} justifyContent={'flex-start'} mb={5}>
								<Text mr={3} fontWeight={'bold'}>
									Status:{' '}
								</Text>
								<Text mr={3}>{simulation.status}</Text>
							</Flex>

							{isParent && haveRecording && (
								<Flex alignItems={'center'} justifyContent={'flex-start'} mb={5}>
									<Text mr={3} fontWeight={'bold'}>
										Recording:
									</Text>
									<audio controls>
										<source
											src={`${config.qa_api.url}/files/getFile?type=recordings&id=${sessionId}`}
											type="audio/wav"
										/>
										Your browser does not support the audio element.
									</audio>
								</Flex>
							)}

							{simulation.status === 'calculated' && (
								<Flex flexDirection={'row'}>
									{renderStat(simulation, 'translationsQuality', true)}
									{renderStat(simulation, 'fragmentStats')}
									{renderStat(simulation, 'translationsLatency')}
								</Flex>
							)}
						</Flex>
					);
				})}
			<Modal isOpen={isOpen} onClose={() => {}}>
				<ModalOverlay />
				<ModalCloseButton />

				<ModalContent bg="#F0F3F6">
					<ModalHeader>
						<Text variant="beTextHeading" fontSize="18px">
							{Locale.get('Create new simulation')}
						</Text>
					</ModalHeader>
					<ModalBody>
						<Flex flexDirection={'column'} justifyContent={'stretch'}>
							<FormControl>
								<Input
									justifyContent={'stretch'}
									variant="beVariant"
									placeholder={Locale.get('Simulation name')}
									value={newSimulation?.name}
									borderColor="black"
									fontWeight={500}
									onChange={e => {
										setNewSimulation({
											...newSimulation,
											name: e.target.value,
										});
									}}
								/>
							</FormControl>
							<Divider mt="7px" mb="15px" />
							<Text
								fontSize="16px"
								mb="8px"
								fontWeight={600}
								textTransform="capitalize"
							>
								{Locale.get('Tags')}
							</Text>
							{newSimulation &&
								newSimulation.tags.map((tag: any) => {
									return (
										<Flex key={tag.key} flexDirection={'column'}>
											<Text mt={3} fontSize={11}>
												{tag.key}
											</Text>
											<Input
												variant="beVariant"
												placeholder={tag.value}
												value={tag.value}
												borderColor="black"
												fontWeight={500}
												onChange={e => {
													onTagChange(tag.key, e.target.value);
												}}
											/>
										</Flex>
									);
								})}
							<Divider mt="24px" mb="24px" />

							{!isCreating && (
								<Flex flexDirection={'row'} justifyContent={'space-between'}>
									<Button
										variant="bePrimary"
										onClick={addNewSimulation}
										textAlign="center"
										w="full"
									>
										{Locale.get('Create new')}
									</Button>

									<Button onClick={onClose} textAlign="center" w="full">
										{Locale.get('Cancel')}
									</Button>
								</Flex>
							)}
							{isCreating && (
								<Flex
									flexDirection={'row'}
									justifyContent={'center'}
									alignItems={'center'}
								>
									<Spinner />
								</Flex>
							)}
						</Flex>
					</ModalBody>
				</ModalContent>
			</Modal>
		</Flex>
	);
};

export default RenderSessionSimulations;
