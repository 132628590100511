import React, { useEffect, useState } from 'react';
import { Box, Flex, Center, Spinner } from '@chakra-ui/react';
import config from '../utils/config';
import { GET_USER } from '../models/users/urls';
import UserModel from '../models/users/users';
import SideBarNavigation from '../components/navigationPanel/SideBarNavigation';
import { Routes, Route } from 'react-router-dom';
import Translation from '../components/pages/Translation';
import LLM from '../components/pages/LLM';
import Statistics from '../components/pages/Statistics';
import BonfireAPI from '../models/BonfireApi';
import LanguageDetection from '../components/pages/LanguageDetection';
import SpeechToSpeechFlow from '../components/pages/SpeechToSpeechFlow';
import SpeechToSpeechFlowRenderer from '../components/pages/SpeechToSpeechFlow/RenderSession';
import RenderSessionChunks from '../components/pages/SpeechToSpeechFlow/RenderSessionChunks';
import RenderSessionSimulations from '../components/pages/SpeechToSpeechFlow/RenderSessionSimulations';

const App: React.FC = () => {
	const [isLoggedIn, setIsLoggedIn] = useState(false);

	const checkIsLoggedIn = async () => {
		try {
			(await BonfireAPI.request(GET_USER, UserModel)) as UserModel;
			setIsLoggedIn(true);
		} catch (error) {
			redirectToLogin();
		}
	};

	const redirectToLogin = () => {
		const url = window.location;
		const data = { successRedirect: `${url}` };
		const encodedData = btoa(JSON.stringify(data));

		const loginUrl = `${config.login.url}/login?t=${encodedData}`;
		document.location.href = loginUrl;
	};
	useEffect(() => {
		checkIsLoggedIn();
	}, []);

	if (!isLoggedIn) {
		return (
			<Center
				display="flex"
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
				h={['full', 'full', 'calc(100vh)']}
			>
				<Spinner />
			</Center>
		);
	}

	return (
		<Flex bg="white" flexDir={{ sm: 'column', md: 'row' }}>
			<SideBarNavigation />
			<Box
				p="23px"
				bg="#F0F3F6"
				w="full"
				maxH={{ sm: 'calc(100svh - 60px)', md: '100svh' }}
				overflow="auto"
			>
				<Routes>
					<Route path="/statistics" element={<Statistics />} />
					<Route path="/translation" element={<Translation />} />
					<Route path="/llm" element={<LLM />} />
					<Route path="/language-detection" element={<LanguageDetection />} />
					<Route path="/s2s-flow" element={<SpeechToSpeechFlow />} />
					<Route path="/s2s-flow/:sessionId" element={<SpeechToSpeechFlowRenderer />} />
					<Route path="/s2s-flow/chunks/:sessionId" element={<RenderSessionChunks />} />
					<Route
						path="/s2s-flow/simulations/:sessionId"
						element={<RenderSessionSimulations />}
					/>
				</Routes>
			</Box>
		</Flex>
	);
};

export default App;
